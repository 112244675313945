<script setup lang="ts">
import { useTgCustomer } from '~/hooks/useTgCustomer'
import { useAvailableEnergy } from '~/composables/app/useAvailableEnergy'

const { config, usableEnergy } = useAppState()
const tgLink = useTgCustomer()

const { rentableData, rentableColumns, deilColumns, deilData } = useAvailableEnergy()

const maxEnergy = computed(() => Math.max(...Object.values(usableEnergy.value?.energy_available ?? {})))
</script>

<template>
  <div class="rental-about">
    <h1>{{ $t("syeMRIeBr3TkMUaTmUyEH") }}</h1>
    <h2>{{ $t("oduMMionIcpsZzCk4KfCv") }}</h2>
    <div class="introduce">
      <ul>
        <li>{{ $t("9AKzqTdGSjOISWkIssZVC") }}</li>
        <li>
          <template v-if="currentLocale === 'zh'">
            范围更大，支持{{
              formatAmount(divide(config?.order.energy_min_value, 10000) || 1, 4)
            }}
            万起租
          </template>
          <template v-else>
            Larger scope, rent starting at {{
              formatAmount(config?.order.energy_min_value || 10000, 4)
            }}
          </template>
        </li>
        <li>{{ $t("hZmrfFgil-GMjpg5XjvK-") }}</li>
        <li>{{ $t("Fj3uG--uDEz5fulPQF-dD") }}</li>
        <li>
          <span>{{ $t("q5G5zCbxiMgQPwmZFMDvo") }}</span>
          <a
            class="customer-link"
            target="_blank"
            :href="tgLink"
            :style="{ color: '#266ef1' }"
            data-test-id="kMIq"
          >
            {{ $t("ynGYicmIA4FVfXRlTrsjf") }}
          </a>
        </li>
      </ul>
    </div>
    <div class="info">
      <ul>
        <li>
          {{ $t("mlhc829WjrAXwBEZdSw") }}
          <span>
            {{ formatAmount(maxEnergy, 0) }}
          </span>
          <AvailablePopover
            :title="$t('mlhc829WjrAXwBEZdSw')"
            :columns="rentableColumns"
            :dataSource="rentableData"
          />
        </li>
        <li>
          {{ $t("tosUM-DG_T6duJ3EsxMN5")
          }}<span> {{ formatAmount(usableEnergy?.freed_energy, 0) }} </span>
          <AvailablePopover
            :title="$t('ysILvWajcBBsMaUnyucNG')"
            :columns="deilColumns"
            :dataSource="deilData"
          />
        </li>
        <li>
          {{ $t("X0ZcIkeG78Dyf-5rb_RM4") }}<span>{{ formatAmount(Math.max(config?.order.energy_c2c_max_value ?? 0, config?.order.energy_max_value ?? 0), 0) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rental-about {
  --uno: 'relative flex-[1] min-w-280px max-w-630px mr-[40px] lt-tabletl:hidden';

  h1 {
    --uno: 'mb-[22px] font-800 text-90px lh-7.3rem text-transparent';

    position: relative;
    background: linear-gradient(
      98deg,
      rgb(38 128 235 / 100%) 0%,
      rgb(0 193 165 / 100%) 59%
    );
    background-clip: text;
  }

  h2 {
    --uno: 'font-600 text-48px text-[--first-text]';
  }

  .introduce {
    --uno: 'relative mt-[40px]';

  }

  .info {
    --uno: 'mt-[25px]';

    position: relative;
    padding-top: 27px;
    font-size: 20px;
    color: var(--secondary-text);

    ::before {
      position: absolute;
      top: 0;
      left: 10px;
      width: 340px;
      height: 1px;
      content: '';
      background: linear-gradient(to right, #2a47ab, #2a47ab, transparent 50%)
        repeat-x;
      background-size: 8px 1px;
    }

    .customer-link {
      --uno: 'text-[--highlight] mx-[5px] my-[0]';
    }

    .detail {
      --uno: 'text-[--highlight] mx-[5px] my-[0] cursor-pointer whitespace-nowrap';
    }
  }

  ul {
    --uno: '[list-style:initial] leading-[1.8em] text-[var(--secondary-text)] text-20px';

    display: inline;
    list-style-type: none;

    li {
      padding-left: 10px;
      margin-left: 30px;
      list-style: url('@/assets/imgs/dot.svg') outside;
    }
  }

}
</style>

import type { Component } from 'vue'
import type { ComponentExposed } from 'vue-component-type-helpers'

export function useComponentRef<C extends Component>(_component: C) {
  const _cpt = ref<any>()
  const refFn = (cpt: any) => {
    _cpt.value = cpt
  }

  type Exposed = typeof refFn & Partial<ComponentExposed<C>>

  return new Proxy(refFn as Exposed, {
    get(_target, p) {
      if (!_cpt.value)
        return

      return Reflect.get(_cpt.value, p)
    },
  })
}

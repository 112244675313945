<script setup lang="ts">
import View from '~/pages-components/b2c/index.vue'

const i18nHead = useLocaleHead({ addSeoAttributes: true })
useHead({
  title: () => `${$t('oGsU5WH4FzlxRU5oDwZ8d')} - ${$t('frQunJ0hl7XTHy3-YC9jW')}`,
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [
    ...(i18nHead.value.meta || []),
    { property: 'og:image', content: '/favicon.svg' },
  ],
})
</script>

<template>
  <ClientOnly>
    <View />
    <template #fallback>
      <div class="flex-center h400px">
        <i class="i-svg-spinners:180-ring-with-bg block text-text-blue text-30px" />
      </div>
    </template>
  </ClientOnly>
</template>

<script lang="ts" setup>
import type { ColumnsType } from 'ant-design-vue/es/table'
import { reactiveComputed } from '@vueuse/core'
import { useAppBreakpoints } from '@peng_kai/theme/vue/index'
import { useQuery } from '@tanstack/vue-query'
import AutoDetail from './AutoDetail.vue'
import AutomaticPopup from './AutomaticPopup.vue'
import { useUserInfo } from '~/composables/app'
import ListEmpty from '~/pages-components/c2c/components/history/ListEmpty.vue'

const visible = useBoolean()
const setType = ref('edit')
const editRow = ref()
const showLoginConfirmPopup = ref(false)
const LoginConfirmPopup = defineAsyncComponent(
  () => import('./LoginConfirmPopup.vue'),
)
const { hasLogged } = useUserInfo()
const { listData, pagination, total, refetch, isFetching } = useAutoLog()

function columns(): ColumnsType {
  return [
    {
      title: $t('wl2jDaljc20gK9boATaeL'),
      dataIndex: 'status',
      align: 'left',
    },
    {
      title: $t('cbDo4K4ai4h5P4iDwFc8J'),
      dataIndex: 'wallet_address',
      align: 'left',
    },
    {
      title: $t('ql35XvnxfLScaw_3txzL'),
      dataIndex: 'resource_type',
      align: 'left',
    },
    {
      title: $t('9NHyLTE3o5hekNv-yuTM7'),
      dataIndex: 'resource_threshold',
      align: 'left',
    },
    {
      title: $t('T6wZU2m_6iKEc-YsBvbih'),
      dataIndex: 'order_resource_value',
      align: 'left',
    },
    {
      title: $t('6v4NWJEIHf0g03aNNrpaf'),
      dataIndex: 'price_in_sun',
      align: 'left',
      customRender: ({ value }) => (value ? `${formatAmount(value)} SUN` : '-'),
    },
    {
      title: $t('F3BUGFYHzOfCq95QzYHwD'),
      dataIndex: 'timeColumn',
      align: 'left',
    },
    {
      title: $t('JAWnNh9xaO1a6wvYwnF3V'),
      dataIndex: 'amount_limit',
      align: 'left',
    },
    {
      title: $t('Zoxdb3CKEIj7JrZH5gr63'),
      dataIndex: 'operate',
      align: 'center',
    },
  ]
}
function getLimitText(record: any) {
  const { limit_type, amount_used, amount_limit, count_used, count_limit } = record
  if (!limit_type)
    return $t('hPpt9sQblVBpXvoOfCOfD')

  const isCount = limit_type === 2
  const prefix = isCount ? count_used : amount_used
  const suffix = isCount ? count_limit : amount_limit
  const last = isCount ? $t('mOtiied8ak122aaMnQiyz') : 'TRX'
  return `${formatAmount(prefix)}/${formatAmount(suffix)} ${last}`
}

function useAutoLog() {
  const bp = useAppBreakpoints()
  const pagination = reactiveComputed(() => {
    return {
      current: 1,
      pageSize: bp.tabletl ? 10 : 5,
    }
  })

  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: [
      apis.cronAddressList.id,
      pagination,
      hasLogged,
    ],
    enabled: hasLogged,
    refetchOnWindowFocus: false,
    queryFn: ({ signal }) =>
      apis.cronAddressList(
        {
          page: pagination.current,
          page_size: pagination.pageSize,
        },
        { errorMessageMode: 'none', signal },
      ),
  })
  const listData = computed(() => {
    if (data.value?.list)
      return data.value?.list.map(item => ({ ...item, loadSwitch: false }))
    else return []
  })
  const total = computed(() => data.value?.pagination.total)

  return {
    listData,
    pagination,
    isLoading,
    isFetching,
    total,
    refetch,
  }
}

async function changeSwitchFn(e: number, row: any) {
  try {
    const isDisable = e === 1
    const apiFn = isDisable ? apis.cronDisable : apis.cronEnable
    row.loadSwitch = true
    await apiFn({ requestBody: row }).then(() => {
      aMessage.success(isDisable ? $t('REw0k3fS02oLRJEaMXHcn') : $t('5GuAmhDaxoh3nimNrDO94'))
    }).catch((error) => {
      if (!isDisable && error.code === 15027)
        isRecharge.value = true
    })
    await refetch()
  }
  finally {
    row.loadSwitch = false
  }
}
const isAutoPopup = ref(false)
const isRecharge = ref(false)
const record = ref()
function open(row: any) {
  record.value = row
  visible.setTrue()
}
function openEdit(row: any) {
  isAutoPopup.value = true
  editRow.value = row
}
function checkLoginStatus() {
  showLoginConfirmPopup.value = true
}
function toLogin() {
  globalModal.AuthModal?.openLogin?.(
    {
      loginSuccessCallback() {
        isAutoPopup.value = false
      },
    },
  )
}
function updateDate() {
  isAutoPopup.value = false
  // queryClient.invalidateQueries({
  //   queryKey: [apis.cronAddressList.id],
  //   exact: false,
  // })
  refetch()
}
function onSubmit() {
  window.location.href = `/console/buyer/recharge?lang=${$t('console-lang')}`
}
</script>

<template>
  <div class="auto-log-container">
    <div class="table w-[100%]">
      <ATable
        class="ant-table-striped"
        :columns="columns()"
        :data-source="listData"
        :pagination="false"
        :loading="isFetching"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'status'">
            <ASwitch
              :checked="record.status"
              :loading="record.loadSwitch"
              :checkedValue="1"
              :unCheckedValue="2"
              @change="changeSwitchFn(record.status, record)"
            />
          </template>
          <template v-if="column.dataIndex === 'wallet_address'">
            <ATooltip placement="top">
              <template #title> {{ record[column.dataIndex] }}</template>
              <div>
                {{ desensitization(record[column.dataIndex], 4, -4) }}
              </div>
            </ATooltip>
          </template>
          <template v-if="column.dataIndex === 'resource_type'">
            {{
              record.resource_type === 1
                ? $t("YUG0RoDj9Kcw8PtVRPR9Y")
                : $t("IgN1EoVRFTDyCVLbFG-oT")
            }},
            {{ timeFn(record.rent_duration, record.rent_time_unit) }}
          </template>
          <template v-if="column.dataIndex === 'resource_threshold' || column.dataIndex === 'order_resource_value'">
            {{ formatAmount(record[column.dataIndex]) }}
          </template>
          <template v-if="column.dataIndex === 'timeColumn'">
            <div v-if="record.start_time & record.end_time">
              <div class="common-font">
                <span class="text-[13px]">{{ $t('U-gCYp5GdfHHpcQQccpLe') }}：</span>
                {{ $d(record.start_time * 1000, 'full') }}
              </div>
              <div class="common-font">
                <span class="text-[13px]">{{ $t('c5oHdBm494qykH0i3Z3-d') }}：</span>
                {{ $d(record.end_time * 1000, 'full') }}
              </div>
            </div>
            <div v-else>
              {{ $t('J0VhGdrPELP6MuHk2LwYP') }}
            </div>
          </template>
          <template v-if="column.dataIndex === 'amount_limit'">
            {{ getLimitText(record) }}
          </template>
          <template v-if="column.dataIndex === 'operate'">
            <div class="flex items-center gap-x-4 flex-wrap justify-center text-[#1c82f3] cursor-pointer">
              <span class="common-link" data-test-id="dslg" @click="open(record)">
                {{ $t('r69QbgX0pn9Ig8tTzpe') }}
              </span>
              <span class="common-link" data-test-id="jlln" @click="openEdit(record)">
                {{ $t('5Deoz50i74zSuNyjmIqo3') }}
              </span>
            </div>
          </template>
        </template>
        <template #emptyText>
          <ListEmpty :class="{ invisible: isFetching }">
            <div v-if="hasLogged">{{ $t("Q8smHu0l0nMmaFpE7_DHE") }}</div>
            <div v-else>
              {{ $t('lh4uhusd76cVofgnBoylP') }}
              <span class="text-[#1677ff] cursor-pointer" data-test-id="zmcd" @click="checkLoginStatus">{{ $t('etUUSzcjxsno01JsjSyF') }}</span>
            </div>
          </ListEmpty>
        </template>
      </ATable>
    </div>
    <!-- 移动端 -->
    <div class="mobile-box tc">
      <ASpin :spinning="isFetching">
        <div v-if="listData?.length">
          <div
            v-for="item in listData" :key="item.order_id"
            class="list-box"
          >
            <div class="header">
              <div class="title">
                <i v-if="item.resource_type === 0" class="i-local:tron-bw2-s2 text-20px" />
                <i v-if="item.resource_type === 1" class="i-local:energy-s2 text-20px" />
                {{ desensitization(item.wallet_address, 4, -4) }}
              </div>
              <div class="extra">
                <ASwitch
                  :checked="item.status"
                  :loading="item.loadSwitch"
                  :checkedValue="1"
                  :unCheckedValue="2"
                  @change="changeSwitchFn(item.status, item)"
                />
              </div>
            </div>
            <ul>
              <li>
                {{ $t('fh7YBiRdaJzhDEcSlHPu', { type: item.resource_type === 1
                  ? $t("YUG0RoDj9Kcw8PtVRPR9Y")
                  : $t("IgN1EoVRFTDyCVLbFG-oT") }) }}： <span>{{ formatAmount(item.resource_threshold) }}</span>
              </li>
              <li>
                {{ $t('T6wZU2m_6iKEc-YsBvbih') }}：<span>{{ formatAmount(item.order_resource_value) }}</span>
              </li>
              <li>
                {{ $t('6v4NWJEIHf0g03aNNrpaf') }}：<span>{{ item.price_in_sun ? `${formatAmount(item.price_in_sun)} SUN` : '-' }}</span>
              </li>
              <li>
                {{ $t("p0TcyLOMXm3GmV5tWIlau") }} <span>
                  {{ timeFn(item.rent_duration, item.rent_time_unit) }}
                </span>
              </li>
              <li v-if="item.start_time">{{ $t('U-gCYp5GdfHHpcQQccpLe') }}：<span>{{ item.start_time ? $d(item.start_time * 1000 ?? 0, 'full') : '-' }}</span></li>
              <li v-if="item.end_time ">{{ $t('c5oHdBm494qykH0i3Z3-d') }}：<span>{{ item.end_time ? $d(item.end_time * 1000 ?? 0, 'full') : '-' }}</span></li>
              <li v-if="!item.start_time || !item.end_time">{{ $t('F3BUGFYHzOfCq95QzYHwD') }}：<span>{{ $t('J0VhGdrPELP6MuHk2LwYP') }}</span></li>
              <li>{{ $t('JAWnNh9xaO1a6wvYwnF3V') }}：<span>{{ getLimitText(item) }}</span></li>
            </ul>
            <div class="operate">
              <span data-test-id="eky9" @click="open(item)">{{ $t('r69QbgX0pn9Ig8tTzpe') }}</span>
              <span class="ml-10px" @click="openEdit(item)">{{ $t('5Deoz50i74zSuNyjmIqo3') }}</span>
            </div>
          </div>
        </div>
        <ListEmpty v-else :class="{ invisible: isFetching }">
          <div v-if="hasLogged">{{ $t("Q8smHu0l0nMmaFpE7_DHE") }}</div>
          <div v-else>
            {{ $t('lh4uhusd76cVofgnBoylP') }}
            <a class="text-[#1677ff] cursor-pointer" data-test-id="bwwg" @click="checkLoginStatus">{{ $t('etUUSzcjxsno01JsjSyF') }}</a>
          </div>
        </ListEmpty>
      </ASpin>
    </div>
    <div class="page-box">
      <APagination
        v-model:current="pagination.current"
        :pageSize="pagination.pageSize"
        :total="total"
        size="default"
        :hideOnSinglePage="true"
        :showSizeChanger="false"
      />
    </div>
    <AModal
      v-model:open="visible.is"
      wrapClassName="ant-cover__basic-modal ant-cover__basic-modal-table"
      :width="1000"
      destroyOnClose
      closable
      :maskClosable="false"
      :footer="null"
    >
      <AutoDetail :formData="record" :close="visible.setFalse" />
    </AModal>
    <LoginConfirmPopup v-model="showLoginConfirmPopup" @confirm="toLogin" />
    <!-- 自动租赁 -->
    <AModal
      v-model:open="isAutoPopup"
      destroyOnClose
      wrapClassName="ant-cover__basic-modal"
      :footer="null"
      :maskClosable="false"
      width="540px"
    >
      <AutomaticPopup v-model:setType="setType" v-model:editRow="editRow" :successFunc="updateDate" />
    </AModal>
    <!-- 余额不足 -->
    <AModal
      wrapClassName="ant-cover__basic-modal"
      :open="isRecharge"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      @cancel="isRecharge = false"
    >
      <div class="text-24px text-center text-[#e2e8f2] mx-auto my-32px">
        {{ $t('JjOFfjfValH5_K1eic28B') }}
        <p class="text-16px text-[#8098bf] mt-7px">{{ $t('uNPTDYiOTiVHN6SFYVbC0') }}</p>
      </div>
      <AButton class="comfirm-btn w-100%" data-test-id="zrbz" @click="onSubmit()">{{ $t('LKg2USUlf8zhktD2qU0AV') }}</AButton>
    </AModal>
  </div>
</template>

<style lang="scss" scoped>
@media bp-lt-tablet {

  .auto-log-container {
    margin: 0 0 20px;

    .table {
      display: none;
    }

    .tc {
      display: block;
    }
  }
}

.auto-log-container {
  text-align: center;
}

.ant-cover__basic-modal {
  .ant-modal-content{
    padding: 30px 40px 56px !important;
  }
}

.mobile-box {
  display: none;

  .list-box{
    padding-bottom: 20px;
  }

  .header {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #8098bf;
    background: #202c52;
  }

  .title {
    flex: 2;
    text-align: left;
  }

  ul {
    padding: 20px 16px 15px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #b1bdd1;
    text-align: left;

    li{
      display: flex;
      justify-content: space-between;

      span{
        color: #e2e8f2;
      }
    }
  }

  .operate{
    padding-right: 20px;
    font-size: 14px;
    color: #e2e8f2;
    text-align: right;
    cursor: pointer;

    >span{
      padding: 5px 16px;
      background: #2e3f73;
      border-radius: 6px;
    }
  }
}
</style>

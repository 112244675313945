<script lang="ts" setup>
import ListEmpty from '~/pages-components/c2c/components/history/ListEmpty.vue'
import {CencelOrder} from '~/components/cencel-order';

defineProps<{ listData?: any; loading: boolean ; refresh ?:()=> void}>()
const { isConnected } = tronComp.useAccountCurrent()
const CencelOrderRef = ref<InstanceType<typeof CencelOrder>>()

</script>

<template>
  <div class="collapse">
    <ASpin :spinning="loading">
      <ACollapse v-if="listData?.length" :bordered="false" expandIconPosition="end">
        <ACollapsePanel v-for="item in listData" :key="item.order_id" class="collapse-panel">
          <template #header>
            <div class="flex items-center">
              <div class="flex-1">{{ $d(item.pay_time * 1000) }}</div>
              <div class="flex-1 text-center">
                {{ formatAmount(item.resource_value, 0) }} {{
                  item.resource_type === 1
                    ? $t("YUG0RoDj9Kcw8PtVRPR9Y")
                    : $t("IgN1EoVRFTDyCVLbFG-oT")
                }}
              </div>
              <div class="flex-1 text-right">{{ statusFilters(item.status) }}</div>
            </div>
          </template>
          <ul class="content">
            <li>{{ $t("7RRZ9K8UJCdksj0ANHBPl") }} {{ item.order_no }}</li>
            <li>{{ $t("OLwypolxNjoGJzKSZDdaC") }}{{ item.receive_address }}</li>
            <li>{{ $t("UPlURFuZaraxrxFxPWw_b") }}<span class="text-text-light">{{ formatAmount(item.pay_amount, 2) }}</span> {{ item.pay_symbol }}</li>
            <li>
              {{ $t("p0TcyLOMXm3GmV5tWIlau") }}
              {{ timeFn(item.rent_duration, item.rent_time_unit) }}
            </li>
            <li class="flex-between">
              <div>{{ $t("3DMYaSeIubrgjhyot_RQv") }}{{ item.rent_expire_time ? $d(item.rent_expire_time * 1000, 'full') : "-" }}</div>
              <div>
                <a v-if="item.status === 4 || item.status === 15" @click="CencelOrderRef?.open(item)">{{ $t('KDyDZExlExDP03YbLQy-p') }}</a>
              </div>
            </li>
          </ul>
        </ACollapsePanel>
      </ACollapse>
      <ListEmpty v-else :class="{ invisible: loading }" :isConnected="isConnected" />
      <CencelOrder ref="CencelOrderRef" :refresh="refresh" />
    </ASpin>
  </div>
</template>

<style lang="scss" scoped>
.collapse-panel {
  margin-bottom: 5px;
  border: none !important;

  :deep(.ant-collapse-header) {
    padding: 10px 16px !important;
    font-size: 14px;
    line-height: 1.7 !important;
    color: #8098BF !important;
    background-color: #202C52;
  }

  :deep(.ant-collapse-content-box) {
    padding: 16px 16px 0 !important;
    font-size: 14px;
    color: #E2E8F2 !important;

    li:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }
}

.collapse {
  .content{
    color: #e2e8f2;
  }
}
</style>

<script lang="ts" setup>
import type { ColumnsType } from 'ant-design-vue/es/table'
import { reactiveComputed } from '@vueuse/core'
import { useAppBreakpoints } from '@peng_kai/theme/vue/index'
import { useQuery } from '@tanstack/vue-query'
import { useB2cBuyMachine } from '../machine/useB2cBuyMachine'
import TronCollapse from './TronCollapse.vue'
import ListEmpty from '~/pages-components/c2c/components/history/ListEmpty.vue'
import { orderTypeFilters } from '~/utils'
import { CencelOrder } from '@/components/cencel-order'

const { states } = useB2cBuyMachine()!
const CencelOrderRef = ref<InstanceType<typeof CencelOrder>>()
const { account: tronAccount, isConnected } = tronComp.useAccountCurrent()

function columns(): ColumnsType {
  return [
    { title: '#', dataIndex: 'frozen_tx_id' },
    { title: $t('sY2UA03NsEF3P3EB4yhIq'), dataIndex: 'pay_time' },
    { title: $t('wSbgj1NBjQ2aN8LL5V1pI'), dataIndex: 'status' },
    { title: $t('axfMxezZG51JXiw4NxkkM'), dataIndex: 'order_no' },
    { title: $t('jiir2eTmqaOvbAf1uGj06'), dataIndex: 'receive_address' },
    { title: $t('dbA_bHXDY5bY0REvtG5wh'), dataIndex: 'resource_value' },
    { title: $t('c1R3GTGbhHGUQ-MFaNQpC'), dataIndex: 'rent_duration' },
    { title: $t('fXp_Xapiz8OkrEAx3ToXf'), dataIndex: 'rent_expire_time' },
    { title: $t('rVRzzZPq-cNxU2MMv_7aZ'), dataIndex: 'pay_amount' },
    { title: $t('jekV1ePoZlW4qWmKlH4r'), dataIndex: 'order_type', responsive: ['lg'] },
    { title: $t('Zoxdb3CKEIj7JrZH5gr63'), dataIndex: 'action', responsive: ['lg'], align: 'right' },
  ]
}
const txIdMap = new Map([
  [12, 'refund_tx_id'],
  [14, 'refund_tx_id'],
])
const { listData, pagination, isFetching, total, refetch } = useRentLog()

function useRentLog(): any {
  const bp = useAppBreakpoints()
  const pagination = reactiveComputed(() => {
    return {
      current: 1,
      pageSize: bp.tabletl ? 10 : 5,
    }
  })
  const success = computed(() => states.ttpayPaymentSuccessful || states.paymentSuccessfulOpen)
  const enabled = computed(()=> !!isConnected.value && !!tronAccount.address)

  const { data, isFetching, refetch } = useQuery({
    queryKey: [
      apis.myPayOrder.id,
      tronAccount.address,
      tronAccount.chainId,
      pagination,
      success,
      enabled,
    ],
    enabled,
    refetchOnWindowFocus: false,
    queryFn: () =>
      apis.myPayOrder(
        {
          page: pagination.current,
          page_size: pagination.pageSize,
          trx_address: tronAccount.address,
        },
        { errorMessageMode: 'none' },
      ),
  })

  watch(()=> tronAccount.address,(newVal, oldVal)=>{
    if(!!oldVal && !!newVal && oldVal !== newVal){
      refetch()
    }
  })

  const listData = computed(() => data.value?.list)

  const total = computed(() => {
    return data.value?.pagination.total
  })

  return {
    listData,
    pagination,
    isFetching,
    total,
    refetch,
    enabled
  }
}

</script>

<template>
  <div class="rent-log-panel">
    <div v-if="$bp.tabletl" class="table w-[100%]">
      <ATable
        :columns="columns()"
        :data-source="listData"
        :pagination="false"
        :loading="isFetching"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'frozen_tx_id'">
            <a
              :href="`https://tronscan.org/#/transaction/${record[txIdMap.get(record.status) ?? 'frozen_tx_id']}`"
              target="_blank"
              data-test-id="UQ3i"
            >TxHash</a>
          </template>
          <template v-if="column.dataIndex === 'resource_value'">
            {{ formatAmount(record[column.dataIndex], 0) }}
            {{
              record.resource_type === 1
                ? $t("YUG0RoDj9Kcw8PtVRPR9Y")
                : $t("IgN1EoVRFTDyCVLbFG-oT")
            }}
          </template>
          <template v-if="column.dataIndex === 'order_type'">
            <span>{{ orderTypeFilters(record.order_type) }}</span>
          </template>
          <template v-if="column.dataIndex === 'status'">
            {{ statusFilters(record[column.dataIndex]) }}
          </template>
          <template v-if="column.dataIndex === 'receive_address'">
            <ATooltip placement="top">
              <template #title> {{ record[column.dataIndex] }}</template>
              <div>
                {{ desensitization(record[column.dataIndex], 4, -4) }}
              </div>
            </ATooltip>
          </template>
          <template v-if="column.dataIndex === 'rent_duration'">
            {{ timeFn(record.rent_duration, record.rent_time_unit) }}
          </template>
          <template v-if="column.dataIndex === 'rent_expire_time'">
            <span v-if="record[column.dataIndex]">{{ $d(record[column.dataIndex] * 1000, "full") }}</span>
            <span v-else>-</span>
          </template>
          <template v-if="column.dataIndex === 'pay_time'">
            <ATooltip placement="top">
              <template v-if="record[column.dataIndex]" #title>
                <span>
                  {{ $d(record[column.dataIndex] * 1000, "full") }}</span>
              </template>
              {{ $d(record[column.dataIndex] * 1000) }}
            </ATooltip>
          </template>
          <template v-if="column.dataIndex === 'pay_amount'">
            {{ formatAmount(record[column.dataIndex], 2) }}
          </template>
          <template v-if="column.dataIndex === 'action'">
            <!-- 已支付 和 委托进行中 可以取消订单-->
            <a v-if="record.status === 4 || record.status === 15" @click="CencelOrderRef?.open(record)">{{ $t('KDyDZExlExDP03YbLQy-p') }}</a>
            <span v-else>-</span>
          </template>
        </template>

        <template #emptyText>
          <ListEmpty :class="{ invisible: isFetching }" :isConnected="isConnected" />
        </template>
      </ATable>
    </div>
    <TronCollapse v-else class="main" :listData="listData" :loading="isFetching" :refresh="refetch"/>

    <div class="pagination-wrapper">
      <APagination
        v-model:current="pagination.current"
        class="ant-cover__basic-pagination"
        :pageSize="pagination.pageSize"
        :total="total"
        size="default"
        :hideOnSinglePage="true"
        :showSizeChanger="false"
      />
    </div>

    <CencelOrder ref="CencelOrderRef" :refresh="refetch" />
  </div>
</template>

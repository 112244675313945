// @ts-ignore

import { createMachine } from 'xstate'

export { machine }
export type { IContext }

interface IContext {
  paymentAddress: string
  receiveAddress: string
  resourceValue: number
  rentDuration: number
  rentDurUnit: string
  amount: number
  paymentResult: any
  paymentError: any
  ttpayPollingResult: any
  ttpayPaymentDownOrderResult: any
  autoToMarket: number
  orderNo: string
  orderType: number
  payAddress: string
  paySymbol: string
  payTxId: string
  resourceType: number
  waitSecond: number

}

const machine = createMachine({
  id: 'B2cBuyMachine',
  initial: 'idle',
  states: {
    'idle': {
      on: {
        OPEN: {
          target: 'confirm',
        },
      },
    },
    'confirm': {
      on: {
        PAY: [
          {
            target: 'tronlink',
            cond: 'isTronlink',
            actions: ['setInfo'],
          },
          {
            target: 'ttpay',
            cond: 'isTtpay',
            actions: ['setInfo'],
          },
          {
            target: "balance",
            actions: ['setInfo'],
            guard: {
              type: "isBalance",
            },
          },
        ],
        CLOSE: {
          target: 'idle',
        },
      },
    },
    'tronlink': {
      initial: 'idle',
      states: {
        'idle': {
          always: [
            {
              target: 'order-precreating',
              cond: 'isTronlinkConnected',
            },
            {
              target: 'connecting',
            },
          ],
        },
        'order-precreating': {
          invoke: {
            src: 'precreateTronlinkOrder',
            id: 'precreateTronlinkOrder',
            onDone: [
              {
                target: 'signing',
              },
            ],
            onError: [
              {
                target: '#B2cBuyMachine.confirm',
                actions: [],
              },
            ],
          },
          tags: 'tronlinkCreating',
        },
        'connecting': {
          invoke: {
            src: 'connectTronlink',
            id: 'connectTronlink',
            onDone: [
              {
                target: 'continue',
                actions: {
                  type: 'doneConnectWallet',
                },
              },
            ],
            onError: [
              {
                target: '#B2cBuyMachine.confirm',
                actions: ['handleError'],
              },
            ],
          },
          tags: 'tronlinkCreating',
        },
        'signing': {
          invoke: {
            src: 'signTronlink',
            id: 'signTronlink',
            onDone: [
              {
                target: 'order-creating',
              },
            ],
            onError: [
              {
                target: '#B2cBuyMachine.confirm',
                actions: ['handleError'],
              },
            ],
          },
          tags: 'tronlinkCreating',
        },
        'continue': {
          on: {
            CONFIRMORDER: {
              target: 'idle',
            },
            CLOSE: {
              target: '#B2cBuyMachine.idle',
            },
          },
        },
        'order-creating': {
          invoke: {
            src: 'createTronlinkOrder',
            id: 'createTronlinkOrder',
            onDone: [
              {
                target: 'order-querying',
              },
            ],
            onError: [
              {
                target: '#B2cBuyMachine.confirm',
                actions: ['handleError'],
              },
            ],
          },
          tags: 'tronlinkCreating',
        },
        'order-querying': {
          invoke: {
            src: 'queryTronlinkOrder',
            id: 'queryTronlinkOrder',
            onDone: [
              {
                target: '#B2cBuyMachine.payment-successful',
                actions: ['setPaymentResult'],
              },
            ],
            onError: [
              {
                target: '#B2cBuyMachine.confirm',
                actions: ['handleError', 'orderQueryError'],
              },
            ],
          },
          tags: 'tronlinkCreating',
        },
      },
    },
    'ttpay': {
      initial: 'idle',
      states: {
        'idle': {
          always: {
            target: 'order-precreating',
          },
        },
        'order-precreating': {
          invoke: {
            src: 'precreateTtpayOrder',
            id: 'precreateTtpayOrder',
            onDone: [
              {
                target: 'order-creating',
              },
            ],
            onError: [
              {
                target: '#B2cBuyMachine.confirm',
                actions: ['handleError'],
              },
            ],
          },
          tags: 'ttpayCreating',
        },
        'order-creating': {
          invoke: {
            src: 'createTtpayOrder',
            id: 'orderPrecreat',
            onDone: [
              {
                target: 'order-querying',
                actions: ['setTtpayDownPaymentResult'],
              },
            ],
            onError: [
              {
                target: '#B2cBuyMachine.confirm',
                actions: ['handleError'],
              },
            ],
          },
          tags: 'ttpayCreating',
        },
        'order-querying': {
          invoke: {
            src: 'queryTtpayOrder',
            id: 'queryTtpayOrder',
          },
          tags: 'ttpayWaiting',
          on: {
            ASSERT_SUCCESS: {
              target: '##B2cBuyMachine.ttpay-payment-successful',
              actions: {
                type: 'setTtpayPaymentResult',
                params: {},
              },
            },
            CLOSE: {
              target: '##B2cBuyMachine.idle',
              actions: {
                type: 'onCloseLoop',
                params: {},
              },
            },
            ASSERT_FAILL: {
              target: '##B2cBuyMachine.confirm',
              actions: [
                {
                  type: 'handleError',
                  params: {},
                },
                {
                  type: 'ttpayOrderQueryError',
                  params: {},
                },
              ],
            },
          },
        },
      },
    },
    'balance': {
      initial: 'idle',
      states: {
        'idle': {
          always: {
            target: 'balance-create-order',
          },
        },
        'balance-create-order': {
          invoke: {
            src: 'createBalanceOrder',
            id: 'createBalanceOrder',
            onError: [
              {
                target: '#B2cBuyMachine.confirm',
                actions: {
                  type: 'requestError',
                },
              },
            ],
            onDone: [
              {
                target: 'order-results',
              },
            ],
          },
          tags: [
            'request',
            'balance-request',
          ],
        },
        'order-results': {
          on: {
            OK: {
              target: '#B2cBuyMachine.idle',
            },
          },
        },
      },
    },
    'payment-successful': {
      on: {
        CLOSE: {
          target: 'idle',
        },
      },
    },
    'ttpay-payment-successful': {
      on: {
        CLOSE: {
          target: 'idle',
        },
      },
    },
  },
  schema: {
    events: {} as
      | {
        type: 'PAY'
        paymentMethod: 'dapp' | 'ttpay' | 'balance'
        paymentAddress: string
        receiveAddress: string
        resourceValue: number
        rentDuration: number
        rentDurUnit: string
        amount: number
      }
      | { type: 'CLOSE' }
      | { type: 'OK' }
      | { type: 'OPEN' }
      | { type: 'CONFIRMORDER' }
      | { type: 'ASSERT_FAILL'; ttpayPaymentStatus: number }
      | { type: 'ASSERT_SUCCESS'; ttpayPaymentStatus: number },
  },
  predictableActionArguments: true,
  preserveActionOrder: true,
  context: ({} as unknown) as IContext,
  tsTypes: ({} as unknown) as import('./b2cBuyMachine.typegen').Typegen0,
})

<script lang="ts">
export default {
  name: 'ModalContainer',
}
</script>

<script lang="ts" setup>
const props = defineProps<{
  isRecharge: boolean
  isAble: boolean
  isRest: boolean
  restText?: number
  isDel: boolean
}>()
const emits = defineEmits(['onClick', 'cancel', 'rest', 'delete'])

function onSubmit() {
  window.location.href = `/console/buyer/recharge?lang=${$t('console-lang')}`
}
function onEnable(status: number) {
  emits('onClick', status)
}
function cancel(type: string) {
  emits('cancel', type)
}
function deleteRowFn() {
  emits('delete')
}
function resetAmountFn() {
  emits('rest')
}
</script>

<template>
  <div>
    <!-- 余额不足 -->
    <AModal
      wrapClassName="ant-cover__basic-modal"
      :open="props.isRecharge"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      @cancel="cancel('recharge')"
    >
      <div class="modal-title my-54px">
        {{ $t('JjOFfjfValH5_K1eic28B') }}
      </div>
      <AButton class="comfirm-btn w-100%" @click="onSubmit()" data-test-id="rgqy"> {{ $t('LKg2USUlf8zhktD2qU0AV') }}</AButton>
    </AModal>
    <!-- 启用 -->
    <AModal
      wrapClassName="ant-cover__basic-modal"
      :open="props.isAble"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      @cancel="cancel('able')"
    >
      <div class="modal-title my-32px ">
        {{ $t('-G2Pov0X2Qar_jrS1bx_2') }}
        <p class="text-16px text-[#8098bf] mt-7px">{{ $t('4YitynJpHsBfBCeEWwXo') }}</p>
      </div>
      <div class="flex justify-center items-center mt4 gap4">
        <AButton class="cancel-btn flex-1" @click="onEnable(2)" data-test-id="tc8t">{{ $t('ocYnnN4wEfnnSRS0A60BI') }}</AButton>
        <AButton class="comfirm-btn flex-1" @click="onEnable(1)" data-test-id="ek9p">{{ $t('MkUW4IQS-AgA02v2cXaFN') }}</AButton>
      </div>
    </AModal>
    <!-- 重置 -->
    <AModal
      wrapClassName="ant-cover__basic-modal"
      :open="props.isRest"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      @cancel="cancel('rest')"
    >
      <div class="modal-title my-54px">
        {{ restText === 1 ? $t('y-OOVA0lheOtKEEu3fahP') : $t('0-4PJPWUYptTmwOgBHQFh') }}
      </div>
      <div class="flex justify-center items-center mt4 gap4">
        <AButton class="cancel-btn flex-1" @click="cancel('rest')" data-test-id="fm66">{{ $t('llizsvpXYvvyPWBG5e6MV') }}</AButton>
        <AButton class="comfirm-btn flex-1" @click="resetAmountFn" data-test-id="wcgj">{{ $t('zWjGxjqbs24Sx9fjh3eEg') }}</AButton>
      </div>
    </AModal>
    <!-- 删除 -->
    <AModal
      wrapClassName="ant-cover__basic-modal"
      :open="props.isDel"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      @cancel="cancel('del')"
    >
      <div class="modal-title my-54px">
        {{ $t('ZDxaqqNVziPr75hQQcI-Z') }}
      </div>
      <div class="flex justify-center items-center mt4 gap4">
        <AButton class="cancel-btn flex-1" @click="cancel('del')" data-test-id="6gwp">{{ $t('llizsvpXYvvyPWBG5e6MV') }}</AButton>
        <AButton class="comfirm-btn flex-1" @click="deleteRowFn"  data-test-id="hja9">{{ $t('zWjGxjqbs24Sx9fjh3eEg') }}</AButton>
      </div>
    </AModal>
  </div>
</template>

<style lang="scss">
.modal-title{
  --uno: 'text-24px text-center text-[#e2e8f2] mx-auto';
}
</style>

<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import type { ColumnsType } from 'ant-design-vue/es/table'
import ListEmpty from '~/pages-components/c2c/components/history/ListEmpty.vue'

const props = defineProps(['formData', 'close'])
type TDetail = Api.GetDataItem<typeof apis.cronAddressList>

const record = ref<TDetail>()

onMounted(() => {
  record.value = props.formData
})
// 表格配置
function columnsPc(): ColumnsType {
  return [
    { title: '#', dataIndex: 'frozen_tx_id' },
    {
      title: $t('axfMxezZG51JXiw4NxkkM'),
      dataIndex: 'order_no',
    },
    {
      title: $t('q7x4PadcOtMFpyPEejUf'),
      dataIndex: 'receive_address',
    },
    {
      title: $t('4EL1dsnCzDROotIv3XEDR'),
      dataIndex: 'resource_value',
    },
    {
      title: $t('nXimXOibKRgMIsxgE0v'),
      dataIndex: 'rent_duration',
      width: '80px',
    },
    {
      title: $t('uiB6J5wmqrVMS1jIdBREC'),
      dataIndex: 'time',
    },
    {
      title: $t('rVRzzZPq-cNxU2MMv_7aZ'),
      dataIndex: 'pay_amount',
    },
    {
      title: $t('wl2jDaljc20gK9boATaeL'),
      dataIndex: 'status',
      width: '120px',
      align: 'center',
    },
  ]
}
function columnsMb(): ColumnsType {
  return [
    {
      title: $t('uiB6J5wmqrVMS1jIdBREC'),
      dataIndex: 'timeMb',
      align: 'left',
    },
    {
      title: $t('4EL1dsnCzDROotIv3XEDR'),
      dataIndex: 'resource_value',
      align: 'center',
    },
    {
      title: $t('rVRzzZPq-cNxU2MMv_7aZ'),
      dataIndex: 'pay_amount',
      align: 'center',
    },
    {
      title: $t('wl2jDaljc20gK9boATaeL'),
      dataIndex: 'status',
      align: 'center',
    },
  ]
}
function getProgressVal(row: any) {
  if (!row)
    return 0
  const { frozen_resource_value, resource_value } = row
  if (!frozen_resource_value || !resource_value)
    return 0
  return ((frozen_resource_value / resource_value) * 100) >> 0
}

function getProgressText(row: any, val: number) {
  if (!row)
    return ''
  if (row?.status === 14)
    return `${$t('UrJhlj4ZYJsTwbxVL8clV') + val}%`
  return `${$t('665JwHNAeEkGcmYs40aBv') + val}%`
}

function statusToText(status: number) {
  const statusMap: Record<number, string> = {
    1: $t('F_iuD-Y18ciyAfwmpFx3m'),
    2: $t('VduH-KOY-mQAZf9mN44hU'),
    3: $t('uAEpoamPGWCaTaieTZ6VR'),
    4: $t('uAEpoamPGWCaTaieTZ6VR'),
    5: $t('uAEpoamPGWCaTaieTZ6VR'),
    6: $t('uYgn3BwaU3w5l9J0fQSMS'),
    7: $t('uAEpoamPGWCaTaieTZ6VR'),
    8: $t('uYgn3BwaU3w5l9J0fQSMS'),
    9: $t('xtdGn0p0PBsKoRkmtF11H'),
    10: $t('uYgn3BwaU3w5l9J0fQSMS'),
    11: $t('xDG7cPnqmx7NhEFYEGu0B'),
    12: $t('xDG7cPnqmx7NhEFYEGu0B'),
    13: $t('Vmvy-0j3tczAi2LLYfIbr'),
    14: $t('UrJhlj4ZYJsTwbxVL8clV'),
    15: $t('uYgn3BwaU3w5l9J0fQSMS'),
    17: $t('xtdGn0p0PBsKoRkmtF11H'),
  }
  return statusMap[status]
}

function statusToColor(status: number) {
  const color: Record<number, string> = {
    1: '#0a8814',
    2: '#db3e3e',
    3: '#fa8c16',
    4: '#fa8c16',
    5: '#fa8c16',
    6: '#3b70dc',
    7: '#fa8c16',
    8: '#3b70dc',
    9: '#00c1a5',
    10: '#3b70dc',
    11: '#db3e3e',
    12: '#db3e3e',
    13: '#db3e3e',
    14: '#db3e3e',
    15: '#3b70dc',
    17: '#00c1a5',
  }
  return color[status]
}

const paginationChange = (page: number) => (pagination.page = page)

const pagination = reactive({
  page: 1,
  pageSize: 5,
})

const queritr = useQuery({
  enabled: computed(() => !!record.value),
  queryKey: [apis.cronOrderList.id, pagination, record],
  refetchOnWindowFocus: false,
  staleTime: 10 * 1000,
  queryFn: () =>
    apis.cronOrderList({
      page: pagination.page,
      page_size: pagination.pageSize,
      start_time: '',
      end_time: '',
      resource_type: record.value?.resource_type,
      wallet_address: record.value?.wallet_address,
    }),
})

const listData = computed(() => queritr?.data.value?.list)
const total = computed(() => queritr?.data.value?.pagination.total)
</script>

<template>
  <div class="box">
    <div class="title">{{ $t('w7OVpX8FnDnefGlIhGFbn') }}</div>
    <AConfigProvider :theme="{ components: { Table: { colorBgContainer: '#202c52', colorFillAlter: '#2e3f73' } } }">
      <ATable
        class="auto-detail-table ant-cover__basic-table define-both-sides-padding"
        size="small"
        :dataSource="listData"
        :loading="queritr.isLoading.value"
        :columns="client.isMobile ? columnsMb() : columnsPc()"
        :pagination="{
          position: ['bottomCenter'],
          pageSize: pagination.pageSize,
          total,
          size: 'default',
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: paginationChange,
        }"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'frozen_tx_id'">
            <ATooltip v-if="record.frozen_tx_id" placement="top">
              <template #title> {{ record[column.dataIndex] }}</template>
              <div>
                {{ desensitization(record[column.dataIndex], 4, -4) }}
              </div>
            </ATooltip>
            <span v-else>-</span>
          </template>
          <template v-if="column.dataIndex === 'order_no'">
            <ATooltip placement="top">
              <template #title> {{ record[column.dataIndex] }}</template>
              <div>
                {{ desensitization(record[column.dataIndex], 4, -4) }}
              </div>
            </ATooltip>
          </template>
          <template v-if="column.dataIndex === 'receive_address'">
            <ATooltip placement="top">
              <template #title> {{ record[column.dataIndex] }}</template>
              <div>
                {{ desensitization(record[column.dataIndex], 4, -4) }}
              </div>
            </ATooltip>
          </template>
          <template v-if="column.dataIndex === 'resource_value'">
            <i v-if="record.resource_type === 0" class="i-local:tron-bw2-s2 text-20px" />
            <i v-if="record.resource_type === 1" class="i-local:energy-s2 text-20px" />
            {{ formatAmount(record.resource_value, 0) }}
            <p v-if="client.isMobile">
              {{ timeFn(record.rent_duration, record.rent_time_unit) }}
            </p>
          </template>
          <template v-if="column.dataIndex === 'rent_duration'">
            {{ timeFn(record.rent_duration, record.rent_time_unit) }}
          </template>
          <template v-if="column.dataIndex === 'time'">
            <p>{{ $t('df9GMidruC4QOsIgP4q2E') }}:{{ record.freeze_time ? $d(record.freeze_time * 1000 ?? 0, "full") : '-' }}</p>
            <p>{{ $t('di7L7vmkyuyJHFciZupm') }}:{{ record.rent_expire_time ? $d(record.rent_expire_time * 1000 ?? 0, "full") : ' -' }}</p>
          </template>
          <template v-if="column.dataIndex === 'timeMb'">
            {{ $d(record.pay_time * 1000 ?? 0, "mdhm") }}
          </template>
          <template v-if="column.dataIndex === 'pay_amount'">
            <span class="text-[#27c1a5]">{{ formatAmount(record?.pay_amount, 2) }}</span>{{ record?.pay_symbol }}
          </template>
          <template v-if="column.dataIndex === 'status'">
            <AProgress
              v-if="
                record?.is_split === 1
                  && record?.order_num > 0
                  && [14, 15].includes(record?.status)
                  && record?.frozen_resource_value < record?.resource_value
              "
              :format="(val) => getProgressText(record, val)"
              :percent="getProgressVal(record)"
            />
            <ATag
              v-else-if="statusToText(record.status)"
              :style="`border: 1px solid ${statusToColor(record.status)};color: ${statusToColor(record.status)};`"
            >
              {{ statusToText(record.status) }}
            </ATag>
            <span v-else>-</span>
          </template>
        </template>
        <template #emptyText>
          <ListEmpty :class="{ invisible: queritr.isLoading.value }" />
        </template>
      </ATable>
    </AConfigProvider>
  </div>
</template>

<style lang="scss" scoped>
.box {
  margin: 0 -40px -30px;

  @media bp-lt-tabletl {
    margin: 0 -20px -10px;
  }
}

.title{
  --uno: 'text-24px text-[#e2e8f2] pl-30px pb-27px text-center lt-tabletl:text-22px lt-tabletl:pl-0 lt-tabletl:pb-20px';
}

.auto-detail-table {
  --both-sides-padding: 30px !important;

  @media bp-lt-tablet {
    --both-sides-padding: 16px !important;
  }

  :deep(.ant-table){
    @media bp-tablet {
      min-height: 300px;
    }
  }
}

:deep(.ant-table-wrapper .ant-table){
  background: transparent;
}

:deep(.ant-progress){
  .ant-progress-text{
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
    color: #e2e8f2;
  }

  .ant-progress-inner{
    background-color: rgb(0 0 0 / 100%)
  }
}
</style>
